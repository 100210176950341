import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/app/node_modules/nextjs-toploader/dist/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/@ui/@navigation/NavigationBar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/@ui/templates/body.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ClientSideCookieConsent"] */ "/app/src/components/cookies/cookie-consent-client.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/footer/footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/misc/scroll-to-top.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/app/src/components/ui/toaster.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/providers/LocaleProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/app/src/providers/theme-provider.tsx");
